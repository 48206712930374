// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { PlusOutlined } from "@ant-design/icons";
import { Card, notification } from "antd";
import Button from "antd/lib/button";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate, useParams } from "react-router";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { updateSelectedMuxVideoUrl, updateSelectedWorkout } from "store/slices";
import {
	useCreateConnectedAccountMutation,
	useCreateCustomerMutation,
	useStripeFetchTransfersMutation,
	useStripeGetCustomerConnectedExternalAccountsMutation,
	useStripeGetCustomerSubscriptionsMutation,
	useGetDomainGymQuery,
	useGetGymMembersByDateQuery,
	useGetGymWorkoutsQuery,
	useGetScheduledStreamsQuery,
	useGetStatsForGymQuery,
	useStripeGetSubscriptionsForGymMutation,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { CreateWorkoutModal } from "components/Modals/CreateWorkoutModal";
import { EditWorkoutModal } from "components/Modals/EditWorkoutModal";
import { ScheduleLiveModal } from "components/Modals/ScheduleLiveModal";
import { GoLiveModal } from "components/Modals/GoLiveModal";
import WorkoutsTable from "components/Workouts/WorkoutsTable";
import { userAtom } from "../../../globalAtoms";
import { UserEditIcon } from "components/Icons/UserEditIcon";
import { ChevronRightIcon, LiveIcon, PencilIcon } from "components/Icons";
import { LoadingMask } from "components/Common/LoadingMask";
import { Paragraph2 } from "themes/default/_typography";
import InviteBanner from "components/UI/InviteBanner";
import { decodeString } from "utils";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------

const GoLiveCard = ({ workout, stream, handleClickEditLive, openGoLiveModal, domainGymData }) => {
	const { name, coach, duration, categories, equipment } = workout;
	const { start_time, time_zone, currently_streaming } = stream;
	const equipmentArray = equipment.split(",")?.filter((item) => !!item);
	const durationInMinutes = Math.ceil(duration / 60);

	return (
		<Card hoverable className="sb-creator-home__section__golive">
			<p className="sb-creator-home__section__golive__caption">
				{currently_streaming ? "Live" : ""}
				{!currently_streaming && dayjs.tz(start_time, time_zone).local().isAfter(dayjs())
					? `live in ${dayjs.tz(start_time, time_zone).local().toNow(true)}`
					: ""}
				{!currently_streaming && dayjs.tz(start_time, time_zone).local().isBefore(dayjs())
					? `was scheduled to start ${dayjs.tz(start_time, time_zone).local().toNow(true)} ago`
					: ""}
			</p>
			<div className="sb-creator-home__section__golive__edit" onClick={() => handleClickEditLive(stream, workout)}>
				<PencilIcon />
			</div>

			<div className="sb-creator-home__section__golive__info">
				<p className="sb-creator-home__section__golive__title">{decodeString(name)}</p>
				<div className="sb-creator-home__section__golive__time">
					<p className="sb-creator-home__section__golive__time__caption">
						{dayjs.tz(start_time, time_zone).format("MMMM D, YYYY h:mm A z")}{" "}
						{dayjs.tz.guess() !== time_zone
							? "(Local : " + dayjs.tz(start_time, time_zone).tz(dayjs.tz.guess()).format("h:mm A") + ") "
							: null}
						| {durationInMinutes}
						mins
					</p>
				</div>
				<div className="sb-creator-home__section__golive__tags">
					<div className="sb-creator-home__section__golive__tags__tag">
						<p className="sb-creator-home__section__golive__tags__tag__caption">{coach}</p>
					</div>
					{categories?.map((category) => {
						return (
							<div key={category.id} className="sb-creator-home__section__golive__tags__tag">
								<p className="sb-creator-home__section__golive__tags__tag__caption">{category.name}</p>
							</div>
						);
					})}
					{equipmentArray?.map((item, i) => {
						return (
							<div key={i} className="sb-creator-home__section__golive__tags__tag">
								<p className="sb-creator-home__section__golive__tags__tag__caption">{item}</p>
							</div>
						);
					})}
				</div>
			</div>
			{currently_streaming === 0 ? (
				<div
					data-cy="go-live-button"
					className="sb-creator-home__section__golive__button"
					onClick={() => openGoLiveModal(workout, stream)}
				>
					Go Live
				</div>
			) : (
				<div className="sb-creator-home__section__golive__islive" onClick={() => openGoLiveModal(workout, stream)}>
					<LiveIcon fill="#FFFFFF" />
					<Paragraph2 style={{ marginBottom: 0, color: "white" }}>BROADCASTING</Paragraph2>
				</div>
			)}

			<img
				className="sb-creator-home__section__golive__image"
				alt="thumbnail"
				style={{ width: "80px", height: "80px", objectFit: "cover" }}
				src={
					workout.image_url ||
					(domainGymData?.light_logo ? domainGymData?.light_logo : "https://via.placeholder.com/130x80")
				}
			/>
		</Card>
	);
};
const Home = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useRecoilValue(userAtom);
	const [showCreateWorkoutModal, setShowCreateWorkoutModal] = useState(false);
	const [showEditWorkoutModal, setShowEditWorkoutModal] = useState(false);
	const [selectedLiveStream, setSelectedLiveStream] = useState(null);
	const [isEditingLive, setIsEditingLive] = useState(false);
	const [showScheduleLiveModal, setShowScheduleLiveModal] = useState(false);
	const [showGoLiveModal, setShowGoLiveModal] = useState(false);
	const [transfers, setTransfers] = useState(null);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;
	const isUsedSBPayments = !!(domainGymResponse?.data?.is_used_sb_payments ?? 1);

	const workoutsResponse = useGetGymWorkoutsQuery({ gymId: domainGymId }, { skip: !domainGymResponse.data });
	const { data: scheduledStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const liveWorkouts =
		workoutsResponse.data?.workouts?.filter(
			(workout) =>
				workout.start_time !== null ||
				moment.tz(workout.start_time, "YYYY-MM-DDTHH:mm:ss", "UTC").isAfter(moment.tz("UTC"))
		) || [];
	const [createStripeCustomerTrigger, createCustomerObj] = useCreateCustomerMutation();
	const [createStripeConnectedAccountTrigger, createConnectedAccountObj] = useCreateConnectedAccountMutation();
	const [getCustomerSubscriptionsTrigger] = useStripeGetCustomerSubscriptionsMutation();
	const [getSubscriptionsForGymTrigger, getSubscriptionsForGymResponse] = useStripeGetSubscriptionsForGymMutation();
	const [getConnectedAccountsTrigger] = useStripeGetCustomerConnectedExternalAccountsMutation();
	const [fetchTransfersTrigger] = useStripeFetchTransfersMutation();
	const { data: statsData } = useGetStatsForGymQuery(
		{ gymId: domainGymId, startDate: "", endDate: "", types: ["workout_start", "workout_user_start"] },
		{
			skip: !domainGymResponse.data,
		}
	);

	const { data: gymMembersData } = useGetGymMembersByDateQuery({
		startDate: moment().startOf("year").format("YYYY-MM-DD"),
		endDate: moment().endOf("year").format("YYYY-MM-DD"),
	});

	const [userIsSubscribed, setUserIsSubscribed] = useState(undefined);
	const [userHasPlan, setUserHasPlan] = useState(undefined);
	const [userPayoutsEnabled, setUserPayoutsEnabled] = useState(undefined);

	const handleClickEditLive = (stream, workout) => {
		setSelectedLiveStream(stream);
		dispatch(updateSelectedWorkout({ workout }));
		setIsEditingLive(true);
		setShowEditWorkoutModal(true);
	};

	const fetchCustomerConnectedExternalAccounts = async () => {
		const accountId = user.connected_account_id || createConnectedAccountObj.data.response.connectedAccountId;
		try {
			const bankAccountsRes = await getConnectedAccountsTrigger({ accountId: accountId }).unwrap();
			if (bankAccountsRes?.data?.length) {
				setUserPayoutsEnabled(true);
			} else {
				setUserPayoutsEnabled(false);
			}
		} catch (error) {
			setUserPayoutsEnabled(false);
		}
	};

	const fetchCustomerCurrentMembershipDetails = async () => {
		try {
			const gymProductResponse = await getSubscriptionsForGymTrigger({
				gymName: domainGymResponse?.data?.name,
			}).unwrap();
			const data = gymProductResponse?.data;
			if (data?.id) {
				setUserHasPlan(true);
			} else {
				setUserHasPlan(false);
			}
		} catch (error) {
			console.error("Error Getting Current Pricing Plan: ", error);
			setUserHasPlan(false);
		}
	};

	useEffect(() => {
		if (getSubscriptionsForGymResponse.isLoading) {
			setUserHasPlan(undefined);
		} else if (getSubscriptionsForGymResponse.isError || !getSubscriptionsForGymResponse.data?.id) {
			setUserHasPlan(false);
		} else if (getSubscriptionsForGymResponse.isSuccess) {
			setUserHasPlan(true);
		}
	}, [getSubscriptionsForGymResponse]);

	const fetchCustomerSubscriptions = async () => {
		try {
			const customerId = user?.customer_id || createCustomerObj?.data?.response?.customerId;
			const customerSubscriptionsResult = await getCustomerSubscriptionsTrigger({
				customerId: customerId,
			}).unwrap();
			if (customerSubscriptionsResult?.subscriptions?.length > 0) {
				setUserIsSubscribed(true);
			} else {
				setUserIsSubscribed(false);
			}
		} catch (error) {
			setUserIsSubscribed(false);
		}
	};

	const fetchTransfers = async () => {
		try {
			const transfersResponse = await fetchTransfersTrigger({
				accountId: user?.connected_account_id || createConnectedAccountObj?.data?.response?.connectedAccountId,
			}).unwrap();
			setTransfers(transfersResponse || 0);
		} catch (error) {}
	};

	const addStripeCustomerId = async () => {
		try {
			await createStripeCustomerTrigger().unwrap();
		} catch (error) {}
	};

	useEffect(() => {
		if (createCustomerObj?.isSuccess) {
			notification.success({
				message: "Customer Created",
			});
			fetchCustomerSubscriptions();
		} else if (createCustomerObj.isError) {
			notification.error({
				message: "Error Creating Customer",
			});
		}
	}, [createCustomerObj]);

	const addStripeConnectedAccountId = async () => {
		try {
			await createStripeConnectedAccountTrigger().unwrap();
		} catch (error) {
			console.error("Error Creating Customer: ");
		}
	};

	useEffect(() => {
		if (createConnectedAccountObj.isSuccess) {
			notification.success({
				message: "Connected Account Created",
			});
			fetchCustomerConnectedExternalAccounts();
		} else if (createConnectedAccountObj.isError) {
			notification.error({
				message: "Error Creating Connected Account",
			});
		}
	}, [createConnectedAccountObj]);

	useEffect(() => {
		if (user) {
			if (user.customer_id) {
				fetchCustomerSubscriptions();
			} else {
				addStripeCustomerId();
			}
			if (user.connected_account_id) {
				fetchCustomerConnectedExternalAccounts();
			} else {
				addStripeConnectedAccountId();
			}
			fetchCustomerCurrentMembershipDetails();
		}
	}, [user]);

	useEffect(() => {
		if (!transfers && user?.connected_account_id) {
			fetchTransfers();
		}
	}, [transfers, user]);

	const openWorkoutCreationModal = useCallback(() => {
		dispatch(updateSelectedWorkout({ workout: null }));
		dispatch(updateSelectedMuxVideoUrl({ url: null }));
		setShowCreateWorkoutModal(true);
	}, [dispatch]);

	const openScheduleLiveModal = useCallback(() => {
		setShowScheduleLiveModal(true);
	}, [dispatch]);

	const openGoLiveModal = useCallback(
		(workout, stream) => {
			// TODO: Update these with redux rtkquery
			dispatch(updateSelectedWorkout({ workout }));
			setShowGoLiveModal(true);
			setSelectedLiveStream(stream);
		},
		[dispatch]
	);

	const renderOverviewCard = (title, value, timeValue, percent) => {
		return (
			<Card hoverable className="sb-creator-home__section__card">
				<p className="sb-creator-home__section__card__title">{title}</p>
				<p className="sb-creator-home__section__card__value">{value}</p>
			</Card>
		);
	};

	const renderWorkoutsCount = () => {
		if (workoutsResponse.data?.workouts) {
			return (
				<div className="sb-creator-home__section__head__title-container__count">
					<label>{workoutsResponse.data?.workouts?.length || 0}</label>
				</div>
			);
		}
	};

	const getUserData = () => {
		const users = gymMembersData?.subscribers;
		const lastMonthDate = moment().subtract(1, "months");
		const lastMonth = users?.filter((u) => lastMonthDate.isBefore(moment(u?.last_login_at)));
		return {
			total: users?.length,
			lastMonth: lastMonth?.length,
			percent: users?.length > 0 ? ((lastMonth?.length / users?.length) * 100).toFixed(1) : 0,
		};
	};

	const userData = getUserData();

	const onEditSetVisible = (bool) => {
		setShowEditWorkoutModal(bool);
		if (!bool) {
			dispatch(updateSelectedWorkout({ workout: null }));
			dispatch(updateSelectedMuxVideoUrl({ url: null }));
		}
	};

	const CheckRow = ({ text, fulfilled, navigationPath, num }) => {
		if (fulfilled) {
			return null;
		}
		return (
			<div
				className="sb-creator-home__section__banner__setup-guide__checklist__checkrow"
				style={{ justifyContent: "space-between" }}
			>
				<h1 className="sb-creator-home__section__banner__setup-guide__checklist__checkrow__text__incomplete">
					{" "}
					<div
						style={{
							height: "24px",
							width: "24px",
							borderRadius: "50%",
							color: "#FF865C",

							border: "1px solid #FF865C",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",

							textAlign: "center",
						}}
					>
						<p style={{ marginBottom: 0 }}>{num}</p>
					</div>
					{text}
				</h1>

				<div
					className="sb-creator-home__section__banner__setup-guide__checklist__checkrow__test"
					onClick={() => navigate(navigationPath)}
				>
					<ChevronRightIcon style={{ height: "16px" }} />
				</div>
			</div>
		);
	};
	const showcard = isUsedSBPayments && (!userIsSubscribed || !userHasPlan || !userPayoutsEnabled);
	const loading = userIsSubscribed === undefined || userHasPlan === undefined || userPayoutsEnabled === undefined;
	const bannerProps = [
		{
			text: "Select Pricing Plan",
			fulfilled: userIsSubscribed,
			navigationPath: "pricing-plan?currentPage=sweatbase",
		},
		{
			text: "Create Member Plan",
			fulfilled: userHasPlan,
			navigationPath: "pricing-plan?currentPage=member",
		},
		{
			text: "Setup Earnings Account",
			fulfilled: userPayoutsEnabled,
			navigationPath: "pricing-plan?currentPage=earnings",
		},
	]
		.filter((i) => !i.fulfilled)
		.map((item, i) => ({ ...item, num: i + 1 }));

	return (
		<div className="sb-creator-home">
			<div className="sb-creator-home__section">
				<InviteBanner />
				{showcard && (
					<div className="sb-creator-home__section__banner">
						{loading ? (
							<div className="sb-creator-home__section__banner__loading">
								<LoadingMask />
							</div>
						) : (
							<>
								<div className="sb-creator-home__section__banner__edit-circle">
									<UserEditIcon />
								</div>
								<div className="sb-creator-home__section__banner__text-container">
									<h1 className="sb-creator-home__section__banner__text-container__title">
										Let's Finish setting up your account
									</h1>
									<h1 className="sb-creator-home__section__banner__text-container__subtitle">
										In order to start receiving members and payments, finish setting up your member plan and payment
										settings.
									</h1>
									<h1 className="sb-creator-home__section__banner__text-container__notice">
										* it may take up to 24 hours for your account to be approved and connected
									</h1>
								</div>
								<div className="sb-creator-home__section__banner__setup-guide">
									<h1 className="sb-creator-home__section__banner__setup-guide__title">Setup Guide</h1>
									{bannerProps.map((item) => (
										<CheckRow key={item.text} {...item} />
									))}
								</div>
							</>
						)}
					</div>
				)}
				{scheduledStreams?.length > 0 && (
					<div className="sb-creator-home__section__head">
						<div className="sb-creator-home__section__head__title-container">
							<h1 className="sb-creator-home__section__head__title-container__title">Starting Soon</h1>
						</div>
					</div>
				)}

				{scheduledStreams &&
					scheduledStreams
						.filter((stream) => {
							// filter out streams that have already ended
							const endTime = dayjs.tz(stream.start_time, stream.time_zone).add(120, "minutes");
							return endTime.isAfter(dayjs());
						})
						.sort((a, b) => {
							const isAfter = dayjs.tz(a.start_time, a.time_zone).isAfter(dayjs.tz(b.start_time, b.time_zone));
							if (isAfter) return 1;
							else return -1;
						})
						.map((stream, i) => {
							const match = liveWorkouts.find((workout) => workout.id === stream.workout_id);
							if (!match) return null;
							return (
								<GoLiveCard
									key={match?.id}
									workout={match}
									stream={stream}
									handleClickEditLive={handleClickEditLive}
									openGoLiveModal={openGoLiveModal}
									domainGymData={domainGymResponse?.data}
								/>
							);
						})}
				<div className="sb-creator-home__section__head">
					<div className="sb-creator-home__section__head__title-container">
						<h1 className="sb-creator-home__section__head__title-container__title">Overview</h1>
					</div>
				</div>
				{renderOverviewCard("EARNINGS", `$ ${(transfers / 100 || 0).toFixed(2)}` || 0, "$0 last month", 0)}
				{renderOverviewCard(
					"TOTAL MEMBERS",
					gymMembersData?.subscribers?.length + gymMembersData?.members?.length || 0,
					`${userData.lastMonth} last month`,
					userData.percent
				)}
				{renderOverviewCard(
					"WORKOUTS STARTED",
					statsData
						?.filter((stat) => stat?.type === "workout_start" || stat?.type === "workout_user_start")
						.reduce((acc, stat) => {
							return +stat.total_views + acc;
						}, 0) || 0,
					"0 last month",
					0
				)}
				{renderOverviewCard(
					"Monthly Active Users",
					userData?.lastMonth || 0,
					`${userData?.lastMonth || 0} last month`,
					userData?.percent
				)}
			</div>
			<div className="sb-creator-home__section">
				<div className="sb-creator-home__section__head">
					<div className="sb-creator-home__section__head__title-container">
						<h1 className="sb-creator-home__section__head__title-container__title">Workout Library</h1>
						{renderWorkoutsCount()}
					</div>
					<div>
						<Button
							style={{ marginRight: "10px" }}
							data-cy="schedule-live-button"
							icon={<PlusOutlined />}
							className="sb-creator-home__section__head__button"
							onClick={openScheduleLiveModal}
						>
							Schedule Live
						</Button>
						<Button
							data-cy="new-workout-button"
							icon={<PlusOutlined />}
							className="sb-creator-home__section__head__button"
							onClick={openWorkoutCreationModal}
						>
							New Workout
						</Button>
					</div>
				</div>
				<div className="sb-creator-home__section__table">
					<WorkoutsTable
						setShowEditWorkoutModal={setShowEditWorkoutModal}
						setIsEditingLive={setIsEditingLive}
						setSelectedLiveStream={setSelectedLiveStream}
					/>
				</div>
			</div>
			<CreateWorkoutModal open={showCreateWorkoutModal} setOpen={setShowCreateWorkoutModal} />
			<EditWorkoutModal
				open={showEditWorkoutModal}
				setOpen={onEditSetVisible}
				isEditingLive={isEditingLive}
				setIsEditingLive={setIsEditingLive}
				liveStream={selectedLiveStream}
			/>
			<ScheduleLiveModal open={showScheduleLiveModal} setOpen={setShowScheduleLiveModal} />
			<GoLiveModal open={showGoLiveModal} setOpen={setShowGoLiveModal} liveStream={selectedLiveStream} />
		</div>
	);
};

export default Home;
