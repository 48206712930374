// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState } from "react";
import { Form } from "antd";
import { TimePicker } from "antd";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormTimeSelect from "./styles";
import { ClockIcon } from "components/Icons/ClockIcon";
import moment from "moment";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const FormTimeSelect = ({ name, label, rules, className, disabled, form, ...rest }) => {
	const format12 = "h:mm A";
	const format24 = "HH:mm";

	const [value, setValue] = useState(moment("00:00:00", format24));

	const onChange = (time) => {
		if (moment(time).isValid()) {
			setValue(moment(time, format24));
		} else {
			setValue(moment(form.getFieldValue(name), format24));
		}
	};

	const onOpenChange = (open) => {
		if (!open && form && moment(value).isValid()) {
			form.setFieldValue(name, moment(value, format24));
		} else {
			setValue(moment(form.getFieldValue(name), format24));
		}
	};

	return (
		<StyledFormTimeSelect className={`${className} sb-form-select`}>
			<Form.Item name={name} label={label} rules={rules} required={false}>
				<TimePicker
					disabled={disabled}
					use12Hours
					showNow={false}
					style={{ width: "100%", height: "46px", borderRadius: "100px" }}
					minuteStep={5}
					format={format12}
					value={value}
					onChange={onChange}
					suffixIcon={<ClockIcon />}
					onOpenChange={onOpenChange}
					{...rest}
				/>
			</Form.Item>
		</StyledFormTimeSelect>
	);
};

export default FormTimeSelect;

