import styled from "styled-components";

const StyledFullScreenPlayer = styled.div`
	overflow: hidden;
	background-color: black;
	minheight: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.full-screen-music {
		.play-pause-controls {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			display: none;

			&__inner {
				display: none;
				position: absolute;
				align-items: center;
				justify-content: center;
				top: calc(50% - 44px);
				left: calc(50% - 44px);
				cursor: pointer;
				width: 88px;
				height: 88px;
				border-radius: 50%;
				background: rgba(0, 0, 0, 0.75);
				backdrop-filter: blur(4px);

				.do-fullscreen {
					color: white;
					font-size: 18px;
				}
				&__pause {
					filter: invert(100%);
				}

				&:hover {
					background: white;

					.play-pause-controls__inner__play {
						filter: invert(100%);
					}

					.play-pause-controls__inner__pause {
						filter: none;
					}
				}
			}

			&:hover {
				.play-pause-controls__inner {
					display: flex;
				}
			}
		}

		.workout-name {
			color: #ccc;
			position: absolute;
			top: 64px;
			left: 64px;
			color: white;
			font-family: DM Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 32px;
			line-height: 32px;
			letter-spacing: 0.35px;
		}

		.music-right-pane-controls {
			position: absolute;
			right: 0;
			top: 0;
			width: 400px;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			backdrop-filter: blur(32px);
			padding-left: 32px;
			padding-right: 32px;
		}
		@media only screen and (max-width: 767px) {
			.music-right-pane-controls {
				position: relative !important;
			}
		}

		.hide-and-show-button {
			position: absolute;
			background: red;
			z-index: 999999;
			top: 50%;
			transform: translateY(-50%);
			width: 40px;
			height: 48px;
			left: -40px;
			background: rgba(0, 0, 0, 0.6);
			backdrop-filter: blur(32px);
			border-bottom-left-radius: 4px;
			border-top-left-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

		.music-controls {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;

			.play-button {
				margin-left: 116px;
				width: 96px;
				height: 96px;
				background: #ffffff;
				border-radius: 100%;
				position: relative;
				cursor: pointer;

				> div {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;

					img {
						width: 22px;
						height: 25px;
					}
				}
			}

			.skip-button {
				margin: 0 24px;
				cursor: pointer;

				img {
					width: 64px;
					height: 64px;
				}
			}

			.home-button {
				margin: 0 42px;
				cursor: pointer;
				display: none;
				img {
					width: 36px;
					height: 36px;
				}
			}
		}

		.music-right-pane-title {
			text-align: center;

			.ant-btn {
				margin-top: 16px;
			}

			.image-container {
				border-radius: 4px;
				position: relative;
				width: 86px;
				height: 86px;
				background-position: center;
				background-size: cover;
				margin: 48px auto 24px auto;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 12px;

				&__inner {
					background-position: center;
					background-size: cover;
					border-radius: 50%;
					width: 50px;
					height: 50px;
				}

				&__text {
					text-align: center;
					background: rgba(0, 0, 0, 0.6);
					color: white;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			h1 {
				font-family: DM Sans, sans-serif;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				text-align: center;
				color: #ffffff;
				line-height: 24px;
			}

			.tags {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				text-overflow: ellipsis;
				overflow: hidden;
				span {
					font-family: DM Sans, sans-serif;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					color: #ffffff;
					opacity: 0.75;
					margin: 4px 3px;
					white-space: nowrap;
				}
			}

			p {
				font-family: DM Sans, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #ffffff;
				opacity: 0.75;
			}
		}

		.music-seek {
			&:hover {
				.ant-slider-track {
					background: white !important;
				}
			}

			.ant-slider-handle {
				display: none;
			}

			.ant-slider-track {
				height: 8px;
				background: #ffffff;
				border-radius: 100px;
			}

			.ant-slider-rail {
				height: 8px;
				background: #ffffff;
				opacity: 0.15;
				border-radius: 100px;
			}
		}

		.volume-slider {
			width: 90%;
			margin-right: 10px;
			margin-left: 17px;
			&:hover {
				.ant-slider-track {
					background: ${({ theme }) => theme.colors.coral} !important;
				}
			}
			.ant-slider-track {
				height: 8px;
				background: ${({ theme }) => theme.colors.coral} !important;
				border-radius: 100px;
			}
			.ant-slider-handle {
				display: block;
			}
			.ant-slider-handle {
				border: none;
				width: 18px;
				height: 18px;
				background: ${({ theme }) => theme.colors.coral};
			}
		}

		.music-seek-container {
			display: flex;
			flex-direction: row;

			img {
				transform: translateY(1px);
				opacity: 0.5;
			}
		}

		.ant-divider {
			background: white;
			opacity: 0.12;
		}

		.music-seek-title {
			font-family: DM Sans, sans-serif;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			color: #ffffff;
			img {
				transform: translateY(-3px);
				margin-right: 3px;
			}
		}
	}
`;

export default StyledFullScreenPlayer;
