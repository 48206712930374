// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import Input from "antd/lib/input";
import moment from "moment";
import { DatePicker } from "antd";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import GymBanner from "../../../components/UI/GymBanner";
import WorkoutsList from "../../../components/Workouts/WorkoutsList";

// -----------------------------------------------------------------------------
// Actions and helpers
// -----------------------------------------------------------------------------
import { CategoriesFilter } from "components/Categories/CategoriesFilter";
import { updateMembersWorkoutsSearchTerm } from "store/slices";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const OnDemand = () => {
	const { RangePicker } = DatePicker;
	const dispatch = useDispatch();
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf("year"),
		endDate: moment().endOf("year"),
	});

	const onDateRangeChange = (dates) => {
		if (dates) {
			setDateRange({
				startDate: dates[0],
				endDate: dates[1],
			});
		}
	};

	const handleSearch = (e) => {
		const { value } = e.target;

		dispatch(updateMembersWorkoutsSearchTerm(!value || value.trim() === "" ? "" : value.toLowerCase()));
	};

	return (
		<div className="sb-on-demand-container">
			<GymBanner />
			<div className="sb-on-demand-container__body">
				<div className="sb-on-demand-container__body__head">
					<h1 className="sb-on-demand-container__body__head__title">Explore Videos</h1>
					<RangePicker
						defaultValue={[dateRange.endDate, dateRange.startDate]}
						allowClear={false}
						popupStyle={{ zIndex: 99999 }}
						ranges={{
							"This Day": [moment().startOf("day"), moment().endOf("day")],
							"This Week": [moment().startOf("week"), moment().endOf("week")],
							"Last Month": [
								moment().startOf("month").subtract(1, "months"),
								moment().startOf("month").subtract(1, "days"),
							],
							"This Month": [moment().startOf("month"), moment().endOf("month")],
							"Last Year": [
								moment().startOf("year").subtract(1, "years"),
								moment().startOf("year").subtract(1, "days"),
							],
							"This Year": [moment().startOf("year"), moment().endOf("year")],
						}}
						onChange={onDateRangeChange}
					/>
				</div>
				<div className="sb-on-demand-container__body__filters">
					<CategoriesFilter />
					<Input
						className="sb-on-demand-container__body__filters__search-input"
						prefix={<SearchOutlined />}
						onChange={handleSearch}
						placeholder="Search Workouts..."
					/>
				</div>
				<WorkoutsList dateRange={dateRange} />
			</div>
		</div>
	);
};

export default OnDemand;
