import { userAtom } from "../globalAtoms";
import { useSetRecoilState } from "recoil";

function useLogout(history, gym) {
	const setUser = useSetRecoilState(userAtom);

	const logout = async () => {
		await setUser(null);
		localStorage.removeItem("creator_jwt_token");
		window.location.href = `/login/${gym}`;
	};

	return {
		logout,
	};
}

export default useLogout;
