// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Heading3 } from "themes/default/_typography";
import { StackedBarChart } from "components/Common/Charts/StackedBarChart";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledEarningsStackedBarChart from "./styles";
import { variables } from "themes/default/_variables";
import moment from "moment";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const EarningsStackedBarChart = ({ transfers }) => {
	const stackedBarChartLabels = Array.from({ length: 12 }, (_, i) => moment().month(i).format("MMM"));

	const bars = [
		{
			name: "Earnings",
			backgroundColor: variables.colors.trout,
		},
	];

	const initializeBarChartData = () =>
		stackedBarChartLabels.reduce((acc, month) => {
			acc[month] = { sum: 0 };
			return acc;
		}, {});

	const barChartData = initializeBarChartData();

	transfers.forEach((transfer) => {
		const earningMonth = moment.unix(transfer.arrival_date).format("MMM");
		if (barChartData[earningMonth]) {
			barChartData[earningMonth].sum += transfer.amount;
		}
	});

	const stackedBarChartData = stackedBarChartLabels.map((month) => ({
		name: month,
		Earnings: (barChartData[month].sum / 100).toFixed(2),
	}));

	return (
		<StyledEarningsStackedBarChart className="sb-earnings-stacked-bar-chart">
			<Heading3>Earnings</Heading3>
			<div className="sb-earnings-stacked-bar-chart__card">
				<StackedBarChart data={stackedBarChartData} bars={bars} formatted />
			</div>
		</StyledEarningsStackedBarChart>
	);
};

export default EarningsStackedBarChart;

