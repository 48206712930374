// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import Modal from "antd/lib/modal";
import { useDispatch } from "react-redux";
import { notification } from "antd";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { updateSelectedWorkout } from "store/slices";
// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { CreateWorkoutForm } from "components/Forms/Workout/CreateWorkoutForm";
import { WorkoutDetailsForm } from "components/Forms/Workout/WorkoutDetailsForm";
import { WorkoutMusicForm } from "components/Forms/Workout/WorkoutMusicForm";
import { CloseIcon } from "components/Icons";
import { Steps } from "components/Common/Steps";
import { Heading10, Paragraph12 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledCreateWorkoutModal from "./styles";
import { UploadAssetsForm } from "components/Forms/Workout/UploadAssetsForm.jsx";
import { useParams } from "react-router";
import {
	useCreateWorkoutMutation,
	useEditWorkoutMutation,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useDeleteWorkoutMutation,
	useGetCategoriesQuery,
} from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateWorkoutModal = ({ open, setOpen }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [values, setValues] = useState({});
	const dispatch = useDispatch();
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const [createWorkoutTrigger, createWorkoutResponse] = useCreateWorkoutMutation();
	const [deleteWorkoutTrigger] = useDeleteWorkoutMutation();
	const [editWorkoutTrigger, editWorkoutResponse] = useEditWorkoutMutation();
	const { refetch: refetchWorkouts } = useGetGymWorkoutsQuery(
		{ gymId: domainGymId },
		{
			skip: !domainGymResponse.data,
		}
	);
	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });

	const closeModal = async () => {
		setOpen(false);
		setCurrentStep(1);
		setConfirmOpen(false);
		// Delete created Draft workout
		if (values.workoutId && values.gym_id) {
			await deleteWorkoutTrigger({
				workoutID: values.workoutId,
				gymID: values.gym_id,
			});
		}
		setValues({});
		refetchWorkouts();
		categoriesResponse.refetch();
	};

	const handleCloseModal = () => {
		setConfirmOpen(true);
	};

	const createOrEditWorkout = async (values, notify) => {
		if (!values.workoutId) {
			const { data, error } = await createWorkoutTrigger(values);
			if (error) {
				return notification.error({
					message: "Something went wrong creating your workout",
				});
			}
			setValues((v) => ({ ...values, workoutId: data?.workout?.id }));
			dispatch(updateSelectedWorkout({ ...data }));
			return notification.success({
				message: "Draft workout created",
			});
		} else {
			setValues(values);
			const { error, data } = await editWorkoutTrigger(values);
			if (data && notify) {
				notification.success({
					message: "Workout created successfully",
				});
			}
			if (error) {
				return notification.error({
					message: "Something went wrong updating your workout",
				});
			}
		}
	};

	const nextStep = async (v) => {
		if (createWorkoutResponse.isLoading || editWorkoutResponse.isLoading) {
			return;
		}
		const newValues = { ...values, ...v };
		await createOrEditWorkout(newValues, currentStep > 3);
		refetchWorkouts();
		if (currentStep <= 3) {
			setCurrentStep(currentStep + 1);
		} else {
			setTimeout(() => {
				setCurrentStep(1);
				setOpen(false);
			}, 1000);
			setValues({});
		}
		await categoriesResponse.refetch();
	};

	const previousStep = (values) => {
		setValues((v) => ({ ...v, ...values }));
		setCurrentStep((s) => s - 1);
	};

	return (
		<>
			<Modal
				title="Confirm"
				open={confirmOpen}
				onOk={() => closeModal()}
				onCancel={() => setConfirmOpen(false)}
				okText="Yes"
				cancelText="Go Back"
				maskClosable={false}
				destroyOnClose
			>
				Are you sure you want to cancel creating this workout?
			</Modal>
			<Modal
				width={656}
				closeIcon={<CloseIcon data-cy="close-create-workout-modal" />}
				open={open}
				onCancel={handleCloseModal}
				footer={null}
				maskClosable={false}
				destroyOnClose
			>
				<StyledCreateWorkoutModal data-cy="create-workout-modal" className="sb-create-workout-modal">
					<div className="sb-create-workout-modal__header">
						<Steps
							className="sb-create-workout-modal__header__steps"
							steps={[
								{ value: "", active: currentStep === 1 },
								{ value: "", active: currentStep === 2 },
								{ value: "", active: currentStep === 3 },
								{ value: "", active: currentStep === 4 },
							]}
						/>
						{currentStep === 1 && (
							<>
								<Heading10 className="sb-create-workout-modal__header__title">Upload Assets</Heading10>
								<Paragraph12>Fill out the information below to create a new workout.</Paragraph12>
							</>
						)}
						{currentStep === 2 && (
							<>
								<Heading10 className="sb-create-workout-modal__header__title">Upload Your Content</Heading10>
								<Paragraph12>Add your workout media below.</Paragraph12>
							</>
						)}
						{currentStep === 3 && (
							<>
								<Heading10 className="sb-create-workout-modal__header__title">Fill in the Details</Heading10>
								<Paragraph12>Add some extra details to help us organize your workout.</Paragraph12>
							</>
						)}
						{currentStep === 4 && (
							<>
								<Heading10 className="sb-create-workout-modal__header__title">Finalize with Music</Heading10>
								<Paragraph12>Spice up your video with our unique music mixes.</Paragraph12>
							</>
						)}
					</div>
					{currentStep === 1 && (
						<CreateWorkoutForm
							nextStep={nextStep}
							previousStep={previousStep}
							values={values}
							loading={createWorkoutResponse.isLoading || editWorkoutResponse.isLoading}
						/>
					)}
					{currentStep === 2 && (
						<UploadAssetsForm
							nextStep={nextStep}
							previousStep={previousStep}
							values={values}
							setValues={setValues}
							loading={createWorkoutResponse.isLoading || editWorkoutResponse.isLoading}
						/>
					)}
					{currentStep === 3 && (
						<WorkoutDetailsForm
							nextStep={nextStep}
							previousStep={previousStep}
							values={values}
							loading={createWorkoutResponse.isLoading || editWorkoutResponse.isLoading}
						/>
					)}
					{currentStep === 4 && (
						<WorkoutMusicForm
							nextStep={nextStep}
							previousStep={previousStep}
							values={values}
							loading={createWorkoutResponse.isLoading || editWorkoutResponse.isLoading}
						/>
					)}
				</StyledCreateWorkoutModal>
			</Modal>
		</>
	);
};

export default CreateWorkoutModal;

