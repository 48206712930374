// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState, useEffect, useCallback } from "react";
import notification from "antd/lib/notification";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { FormErrorTag } from "components/Common/Form/FormErrorTag";
import ImageUpload from "components/ImageUpload";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledUploadAssetsForm from "./styles";
import {
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetS3SignedUrlMutation,
	useEditWorkoutMutation,
	useGetMuxAuthenticatedUrlMutation,
} from "store/api/api";
import axios from "axios";
import { useParams } from "react-router";
import MuxUploadAndVideoPlayer from "./MuxUploadAndPlayer";
import { maxImageAssetSize, imageSizeLimitError } from "helpers/constants";

import "./styles.scss";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const UploadAssetsForm = ({ nextStep, previousStep, setValues, values, loading }) => {
	const [videoFile, setVideoFile] = useState(null);
	const [thumbnailFile, setThumbnailFile] = useState(values?._thumbnail || null);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const getWorkoutsResponse = useGetGymWorkoutsQuery({ gymId: domainGymId }, { skip: !domainGymResponse.data });
	const [getS3SignedUrlTrigger] = useGetS3SignedUrlMutation();
	const [getMuxSignedUrlTrigger, muxSignedUrlResponse] = useGetMuxAuthenticatedUrlMutation();
	const [editWorkoutTrigger] = useEditWorkoutMutation();
	// Second call to the edit workout mutation to save the upload id since the call above uses the response in use effect
	const [editWorkoutUploadIdTrigger] = useEditWorkoutMutation();

	const selectedWorkout = useSelector(selectSelectedWorkout);

	const [uploadReady, setUploadReady] = useState(values.videoUploaded);

	// Function to make axios call to the lambda function to get Mux direct upload url
	const getMuxSignedUrl = useCallback(async () => {
		try {
			const { data, error } = await getMuxSignedUrlTrigger();
			if (error) {
				return notification.error("An error occurred getting Mux Upload signed URL");
			}
			await editWorkoutUploadIdTrigger({
				workoutId: selectedWorkout?.id,
				gym_id: domainGymId,
				upload_id: data.id,
			});
		} catch (error) {
			console.debug("error", error);
		}
	}, [getMuxSignedUrlTrigger, editWorkoutUploadIdTrigger, selectedWorkout?.id, domainGymId]);

	// Use Effect to generate a signed direct upload url for mux component
	useEffect(() => {
		if (!muxSignedUrlResponse?.data?.url && !uploadReady) {
			getMuxSignedUrl();
		}
	}, [getMuxSignedUrl, muxSignedUrlResponse?.data?.url, uploadReady]);

	const onNextClick = async () => {
		if (!videoFile) {
			setVideoFile(undefined);
		}

		if (!thumbnailFile) {
			setThumbnailFile(undefined);
		}
		if ((uploadReady || muxSignedUrlResponse?.data?.id) && thumbnailFile) {
			const fullS3ThumbNail = await uploadImageToS3();
			const { data } = await editWorkoutTrigger({
				workoutId: selectedWorkout?.id,
				gym_id: domainGymId,
				image_url: `${fullS3ThumbNail}`,
			});
			setThumbnailFile(null);
			if (data) {
				getWorkoutsResponse.refetch();
				nextStep();
			}
		}
	};

	const onSaveThumbnail = async (file) => {
		const blobToFile = new File([file], "workoutThumbnail.png", {
			type: "image/png",
		});
		setThumbnailFile(blobToFile);
		setValues((v) => ({ ...v, _thumbnail: blobToFile }));
	};

	const uploadAsset = async (response, thumbnailFile) => {
		const formData = new FormData();

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		for (const field in response.fields) {
			formData.append(field, response.fields[field]);
		}

		formData.append("file", thumbnailFile);

		try {
			await axios.post(response.url, formData, { headers });
		} catch (error) {
			notification.error({
				message: "Error Uploading Image",
				description: "Please try again",
			});
		}
	};

	const uploadImageToS3 = async () => {
		const mimeMap = {
			"image/jpeg": "jpg",
			"image/png": "png",
		};
		let fileType = mimeMap[thumbnailFile.type];
		const s3KeyThumbnail = `assets/${domainGymId}/thumbnail-${new Date().getTime()}.${fileType}`;
		const s3SignedResponse = await getS3SignedUrlTrigger({ key: s3KeyThumbnail, type: "image" });
		await uploadAsset(s3SignedResponse.data.response, thumbnailFile);
		const fullS3ThumbNail = `${s3SignedResponse.data.response.url}/${s3KeyThumbnail}`;
		return fullS3ThumbNail;
	};

	const onPreviousStep = () => {
		previousStep({});
	};

	const onImageCancel = () => {
		setValues((v) => ({ ...v, _thumbnail: null }));
		setThumbnailFile(null);
	};

	const afterUpload = (e) => {
		const file = e?.target?._upload?.file;
		if (file && file?.type?.startsWith("video")) {
			setUploadReady(true);
			setValues((v) => ({ ...v, videoUploaded: true }));
		}
	};

	const changeVideo = () => {
		setUploadReady(false);
		setValues((v) => ({ ...v, videoUploaded: false }));
	};

	const onUploadStart = (e) => {
		const file = e?.detail?.file;
		if (!file || !file?.type?.startsWith("video/")) {
			notification.error({ message: "Invalid file type" });
			return false;
		}
		return true;
	};

	return (
		<StyledUploadAssetsForm>
			<div className="sb-upload-assets-form">
				<MuxUploadAndVideoPlayer
					loading={muxSignedUrlResponse?.isLoading}
					endpoint={muxSignedUrlResponse?.data?.url}
					onSuccess={afterUpload}
					playbackId={selectedWorkout?.playbackId}
					videoUploaded={values?.videoUploaded}
					onUploadStart={onUploadStart}
					onUploadAgain={changeVideo}
				/>
				<div className="sb-upload-assets-form__upload">
					<ImageUpload
						className="sb-upload-assets-form__upload__input"
						onSave={onSaveThumbnail}
						onCancel={onImageCancel}
						type="image"
						title="Upload Thumbnail"
						aspectRatio={300 / 180}
						selected={values._thumbnail}
						sizeLimit={{
							limit: maxImageAssetSize,
							error: imageSizeLimitError,
						}}
					/>
					{thumbnailFile === undefined && !values._thumbnail && <FormErrorTag text="Thumbnail required" />}
				</div>
				<div className="sb-workout-details-form__form__actions">
					<Button
						onClick={onPreviousStep}
						className="sb-release-schedule-form__form__button secondary-filled"
						chevronLeft
						uppercase
					>
						<Paragraph2>Back</Paragraph2>
					</Button>
					{thumbnailFile && uploadReady && (
						<Button
							type="submit"
							onClick={onNextClick}
							className="sb-upload-assets-form__form__button primary-filled"
							saveIcon
							uppercase
							loading={loading}
							disabled={loading}
						>
							<Paragraph2>Next</Paragraph2>
						</Button>
					)}
				</div>
			</div>
		</StyledUploadAssetsForm>
	);
};

export default UploadAssetsForm;

