// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { Select } from "antd";
import { YOUR_INFORMATION_FORM_ID } from "../constants";
import { required } from "helpers/Form/commonFormValidations";
import { userAtom } from "../../../globalAtoms";
import { creatorCreationFlowState } from "../atoms";
import chevronDown from "../../../images/chevron-down-black.svg";
import { useNavigate } from "react-router";
import { Button } from "components/Common/Button";
import { useUpdateUserProfileMutation } from "store/api/api";

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------
const JOB_TITLE_OPTIONS = [
	{ value: "coach", label: "Fitness Instructor/Coach" },
	{ value: "gym owner", label: "Gym Owner" },
	{ value: "content creator", label: "Content Creator" },
	{ value: "something else", label: "Something Else" },
];

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const YourInformationForm = ({ history, location, update }) => {
	const navigate = useNavigate();
	const setUser = useSetRecoilState(userAtom);
	const [updateUserTrigger, updateUserResponse] = useUpdateUserProfileMutation({});

	const [creatorCreationFlow] = useRecoilState(creatorCreationFlowState);
	const user = useRecoilValue(userAtom);

	let initialValues = {
		firstName: creatorCreationFlow.firstName || user?.first_name,
		lastName: creatorCreationFlow.lastName || user?.last_name,
		jobTitle: user?.job_title || "coach",
	};

	const handleUpdateUserProfile = async (formObj) => {
		const { firstName, lastName, phone, jobTitle } = formObj;
		const auth_token = localStorage.getItem("creator_jwt_token");
		try {
			await updateUserTrigger({
				auth_token,
				first_name: firstName,
				last_name: lastName,
				phone_number: phone,
				job_title: jobTitle,
			});
			setUser((prev) => ({
				...prev,
				first_name: firstName,
				last_name: lastName,
				job_title: jobTitle,
			}));
		} catch (e) {
			notification.error({
				message: "An error saving your information",
			});
		}
	};

	useEffect(() => {
		if (updateUserResponse.isSuccess) {
			notification.success({
				message: "Updated user info!",
			});
			//Navigate here as well
			if (!update) {
				navigate("/creator-creation/setup-profile");
			}
		} else if (updateUserResponse.isError) {
			if (updateUserResponse.error.status === 409) {
				notification.error({
					message: updateUserResponse.error?.data?.message,
				});
			} else {
				notification.error({
					message: "There was an error updating user info",
				});
			}
		}
	}, [updateUserResponse, update, navigate]);

	return (
		<Form initialValues={initialValues} onSubmit={handleUpdateUserProfile}>
			{({ handleSubmit }) => {
				return (
					<form id={YOUR_INFORMATION_FORM_ID} onSubmit={handleSubmit}>
						<Label>First Name</Label>
						<Field component="input" name="firstName" validate={required}>
							{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
						</Field>
						<Label>Last Name</Label>
						<Field component="input" name="lastName" validate={required}>
							{({ input, meta }) => <InputField type="text" input={input} meta={meta} />}
						</Field>
						<Label>Job Title</Label>
						<Field component="select" name="jobTitle">
							{({ input, meta }) => (
								<Select
									defaultValue={initialValues.jobTitle}
									className="sb-select"
									suffixIcon={<img src={chevronDown} alt="icon" />}
									onChange={input.onChange}
									value={input.value}
								>
									{JOB_TITLE_OPTIONS.map((option) => (
										<Select.Option key={option.value} value={option.value}>
											{option.label}
										</Select.Option>
									))}
								</Select>
							)}
						</Field>
						<div style={{ height: "22px" }} />
						<Button
							className="primary-filled"
							chevronRight={!update}
							saveIcon={update}
							uppercase
							loading={updateUserResponse.isLoading}
							disabled={updateUserResponse.isLoading}
							onClick={handleSubmit}
						>
							{update ? "Save" : "Continue"}
						</Button>
					</form>
				);
			}}
		</Form>
	);
};

export default YourInformationForm;
