// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useDispatch, useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { selectDomainGym, selectLoginLoading, selectAuthToken } from "store/selectors";
import { autoLogin } from "store/slices";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { userAtom } from "../../globalAtoms";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import Logo from "../../images/logo.svg";
import {
	useGetDomainGymQuery,
	useLazyGetCreatorAccountQuery,
	useLazyGetCreatorProductQuery,
	useLazyGetPagesQuery,
	useStripeGetSubscriptionsForGymMutation,
} from "store/api/api";
import { LoadingMask } from "components/Common/LoadingMask";
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------

const FormHeader = ({ type, page }) => {
	let headerText = type === "signup" ? "Create Your Account" : "Login";
	let subheaderText = type === "signup" ? "Enter your info below" : "Enter your login information below";

	if (page?.sub_text) {
		subheaderText = page.sub_text;
		headerText = page.header_text;
	}

	return (
		<div className="sb-login__container__form-container__header">
			<Link to="/">
				<img className="sb-login__container__form-container__header__image" src={Logo} alt="logo" />
			</Link>
			<h1 className="sb-login__container__form-container__header__title">{headerText}</h1>
			<p className="sb-login__container__form-container__header__subtitle">{subheaderText}</p>
		</div>
	);
};

const LoginSection = ({ type, page, paymentsReady, preview, gymId, headerImage }) => {
	return (
		<div className="sb-login__container">
			<div className="sb-login__container__form-container">
				<FormHeader type={type} page={page} />
				{type === "signup" ? (
					paymentsReady ? (
						<SignUpForm preview={preview} />
					) : (
						<SignUpForm preview={true} />
					)
				) : (
					<LoginForm preview={preview} gymId={gymId} />
				)}
				<div className="sb-login__container__form-container__notes">
					<span className="sb-login__container__form-container__notes__first">
						Are you a gym, trainer, or studio and want to offer content to your members?
					</span>
					<a href="/creator-creation" className="sb-login__container__form-container__notes__last">
						LEARN MORE
					</a>
				</div>
			</div>
			<div className="sb-login__container__image-container">
				<div className="sb-login__container__image-container__inner">
					<div className="sb-login__container__image-container__inner__image" style={headerImage} />
				</div>
			</div>
		</div>
	);
};

const Login = ({ preview, type }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [pages, setPages] = useState(null);
	const [gymId] = useState(null);
	const [paymentsReady, setPaymentsReady] = useState(null);
	let { gym } = useParams();
	const { data: domainGymData, isLoading: domainGymIsLoading, error: domainGymError } = useGetDomainGymQuery(gym);
	const domainGym = useSelector(selectDomainGym);
	const [creatorAccountTrigger] = useLazyGetCreatorAccountQuery({});
	const [creatorProductTrigger] = useLazyGetCreatorProductQuery({});
	const [trigger, result, lastPromiseInfo] = useLazyGetPagesQuery({});
	const [getSubscriptionsForGymTrigger] = useStripeGetSubscriptionsForGymMutation();

	const loginLoading = useSelector(selectLoginLoading);
	const authToken = useSelector(selectAuthToken);
	const user = useRecoilValue(userAtom);
	useEffect(() => {
		if (!user && domainGym?.autologin && !loginLoading) {
			dispatch(autoLogin({ gym_id: domainGym.id }));
		}
	}, [user, domainGym, dispatch, loginLoading]);

	useEffect(() => {
		if (result?.data) {
			setPages(result?.data);
		}
	}, [trigger, result, lastPromiseInfo, domainGymData?.id]);

	useEffect(() => {
		if (!loginLoading && authToken) {
			localStorage.setItem("creator_jwt_token", authToken);
			window.location = window.location.origin + "/dashboard";
		}
	}, [loginLoading, authToken]);

	const fetchGymProduct = async () => {
		try {
			const gymProductResponse = await getSubscriptionsForGymTrigger({
				gymName: domainGymData?.name,
			}).unwrap();

			if (gymProductResponse?.unit_amount >= 0 && gymProductResponse?.transfers_enabled) {
				setPaymentsReady(true);
			} else {
				setPaymentsReady(false);
			}
		} catch (error) {
			console.log(error);
			setPaymentsReady(false);
		}
	};

	const fetchPages = async (gymID) => {
		await trigger(gymID).unwrap();
	};

	const lazyFetchGymProduct = async (gymID) => {
		await creatorProductTrigger({ gymId: gymID }).unwrap();
	};

	const lazyFetchGymAccount = async (gymID) => {
		await creatorAccountTrigger({ gymId: gymID }).unwrap();
	};

	useEffect(() => {
		if (domainGymData?.id) {
			fetchGymProduct();
			fetchPages(domainGymData?.id);
			lazyFetchGymProduct(domainGymData?.id);
			lazyFetchGymAccount(domainGymData?.id);
		}
	}, [domainGymData]);

	const page = user ? user.pages?.find((p) => p.type === "sign_up") : pages?.find((p) => p.type === "sign_up");
	const headerImage = page?.header_image
		? {
				backgroundImage: `url(${page?.header_image})`,
		  }
		: null;

	if (location.search.includes("preview")) {
		preview = true;
	}
	if (user && !preview) {
		return <Navigate to={`/${gym}/dashboard`} />;
	}

	if (domainGymIsLoading) {
		return <LoadingMask />;
	}

	if (!domainGymData?.id || domainGymError) {
		return <Navigate to="/" />;
	}

	return (
		<div className="sb-login">
			<LoginSection
				type={type}
				page={page}
				paymentsReady={paymentsReady}
				preview={preview}
				gymId={gymId}
				headerImage={headerImage}
			/>
		</div>
	);
};

export default Login;
