// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Form, Switch, Tooltip } from "antd";
import moment from "moment";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";
import { useGetCategoriesQuery, useGetDomainGymQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2, Span4 } from "themes/default/_typography";
import { FormTagSelect } from "components/Common/Form/FormTagSelect";
import { FormInput } from "components/Common/Form/FormInput";
import { FormDateSelect } from "components/Common/Form/FormDateSelect";
import { FormTimeSelect } from "components/Common/Form/FormTimeSelect";
import { FormTimezoneSelect } from "components/Common/Form/FormTimezoneSelect";
import { FormSelect } from "components/Common/Form/FormSelect";
import Toggle from "components/UI/Form/Toggle";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledWorkoutDetailsForm from "./styles";
import { defaultTimezone, durationOptions } from "helpers/constants";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutDetailsForm = ({ nextStep, live, previousStep, values, loading }) => {
	const [form] = Form.useForm();
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;

	const [scheduleRelease, setScheduleRelease] = useState(!!values.is_scheduled);
	const [active, setActive] = useState(!values?.draft);
	const [isFree, setIsFree] = useState(!!values.is_free);
	const [currentDatetime, setCurrentDatetime] = useState(null);

	const { data: categoriesData } = useGetCategoriesQuery(domainGymId, {
		skip: !domainGymId,
	});

	const handleChangeActive = (checked, event) => {
		setActive(checked);
	};

	const handleChangeScheduling = (checked, event) => {
		setScheduleRelease(checked);
		if (checked && active) {
			setActive(false);
		}
	};

	const convertStartTime = (workoutDate, workoutTime) => {
		const date = moment(workoutDate).format("YYYY-MM-DD");
		const time = moment(workoutTime).format("HH:mm");
		const startTime = moment.tz(`${date} ${time}`, "UTC").format("YYYY-MM-DD HH:mm:ss");

		return startTime;
	};

	const getPayload = () => {
		const { workoutCategories, workoutEquipment, workoutLength, workoutDate, workoutTime, workoutTimezone } =
			form.getFieldsValue();
		const payload = {
			workoutId: selectedWorkout?.id,
			categories: workoutCategories ? workoutCategories?.join(",") : "",
			equipment: workoutEquipment ? workoutEquipment : "",
			duration: !live ? null : workoutLength ? workoutLength : 0,
			draft: live ? null : active ? 0 : 1,
			start_time: scheduleRelease && !live ? convertStartTime(workoutDate, workoutTime) : null,
			is_free: isFree?.free ? 1 : 0,
			time_zone: scheduleRelease ? workoutTimezone : null,
			is_scheduled: scheduleRelease ? 1 : 0,
			workoutDate,
			workoutTime,
		};
		return payload;
	};

	const onFinish = () => {
		const payload = getPayload();
		nextStep(payload);
	};

	const onPreviousStep = () => {
		const payload = getPayload();
		previousStep(payload);
	};

	return (
		<StyledWorkoutDetailsForm>
			<div className="sb-workout-details-form">
				<Form
					onFinish={onFinish}
					layout="vertical"
					form={form}
					initialValues={{
						workoutCategories: values?.categories ? values.categories?.split(",") ?? undefined : undefined,
						workoutLength: values.duration || undefined,
						workoutEquipment: values.equipment,
						workoutTimezone: values.time_zone || defaultTimezone,
						workoutDate: values.workoutDate,
						workoutTime: values.workoutTime,
					}}
				>
					{categoriesData?.categories?.length > 0 && (
						<FormTagSelect
							name="workoutCategories"
							label="Workout Categories"
							placeholder="Enter Categories"
							options={categoriesData?.categories?.map((cat) => ({
								value: cat.name,
								id: cat.id,
							}))}
						/>
					)}
					{live && (
						<FormSelect
							name="workoutLength"
							placeholder="Workout Length"
							rules={[{ required: true, message: "This field is required" }]}
							label="Workout Length"
							options={durationOptions}
							showSearch={false}
							mode="multiple"
						/>
					)}
					<div className="sb-workout-details-form__form__field">
						<FormInput
							name="workoutEquipment"
							placeholder="Workout Equipment"
							label={
								<span className="sb-workout-details-form__form__field__label">
									<span className="sb-workout-details-form__form__field__label__main">Equipment</span>
									<Span4 className="sb-workout-details-form__form__field__label__extra">(Separate With Commas)</Span4>
								</span>
							}
						/>
					</div>
					{!live && (
						<div className="sb-workout-details-form__form__activation-schedule">
							<div>
								<span
									className="sb-workout-details-form__form__field__label__main"
									style={{ color: scheduleRelease ? "grey" : "black" }}
								>
									Active
								</span>
								<Switch
									checked={active}
									disabled={scheduleRelease}
									onChange={(checked) => handleChangeActive(checked)}
								/>
							</div>
							<div>
								<span className="sb-workout-details-form__form__field__label__main">Schedule For Later?</span>
								<Tooltip title="Your workout will be activated for your members at the selected time">
									<Switch checked={scheduleRelease} onChange={(checked) => handleChangeScheduling(checked)} />
								</Tooltip>
							</div>
						</div>
					)}
					{scheduleRelease && (
						<div style={{ paddingTop: "8px" }}>
							<FormDateSelect
								name="workoutDate"
								label="Workout Date"
								rules={[
									{ required: true, message: "This field is required" },
									() => ({
										validator(_, value) {
											const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
											const now = moment.tz(timezone).startOf("day");
											const selectedDate = moment(value).tz(timezone).startOf("day");

											if (selectedDate.isBefore(now)) {
												return Promise.reject(new Error("The selected date cannot be in the past."));
											}
											return Promise.resolve();
										},
									}),
								]}
								disabledDate={(currentDate) => {
									const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
									const now = moment.tz(timezone).startOf("day");
									const current = currentDate && currentDate.tz(timezone).startOf("day");

									return current && current.isBefore(now);
								}}
								onOpenChange={(open) => {
									if (!open) {
										const workoutDate = form.getFieldValue("workoutDate");
										const workoutTime = form.getFieldValue("workoutTime");

										if (moment(workoutDate).isValid()) {
											setCurrentDatetime(moment(workoutDate).format("YYYY-MM-DD"));
										}

										if (workoutTime) {
											form.validateFields(["workoutTime"]);
										}
									}
								}}
							/>
							<div className="sb-release-form-input-group" style={{ display: "flex", gap: "16px" }}>
								<FormTimeSelect
									name="workoutTime"
									label="Time"
									form={form}
									rules={[
										{ required: true, message: "This field is required" },
										() => ({
											validator(_, value) {
												const workoutDate = form.getFieldValue("workoutDate");
												const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;
												const now = moment.tz(timezone);

												if (workoutDate) {
													const selectedDateTime = moment.tz(
														`${moment(workoutDate).format("YYYY-MM-DD")} ${moment(value).format("HH:mm")}`,
														timezone
													);

													if (selectedDateTime.isBefore(now)) {
														return Promise.reject(new Error("The selected time cannot be in the past."));
													}
												}

												return Promise.resolve();
											},
										}),
									]}
									disabledTime={() => {
										const workoutDate = form.getFieldValue("workoutDate") || moment().startOf("day");
										const timezone = form.getFieldValue("workoutTimezone") || defaultTimezone;

										if (workoutDate) {
											const now = moment.tz(timezone);
											const selectedDate = moment(workoutDate).tz(timezone).startOf("day");

											if (selectedDate.isSame(now, "day")) {
												return {
													disabledHours: () => [...Array(now.hour()).keys()],
													disabledMinutes: (selectedHour) => {
														if (selectedHour === now.hour()) {
															return [...Array(now.minute()).keys()];
														}
														return [];
													},
												};
											}
										}
										return {
											disabledHours: () => [],
											disabledMinutes: () => [],
										};
									}}
									onOpenChange={(open) => {
										if (!open) {
											const workoutDate = form.getFieldValue("workoutDate");

											if (workoutDate) {
												form.validateFields(["workoutDate"]);
											}
										}
									}}
								/>{" "}
								<FormTimezoneSelect
									name="workoutTimezone"
									rules={[{ required: true, message: "This field is required" }]}
									label="Timezone"
									currentDatetime={currentDatetime}
									onChange={(newTimezone) => {
										const workoutDate = form.getFieldValue("workoutDate");
										const workoutTime = form.getFieldValue("workoutTime");

										if (workoutDate) {
											const formattedDate = moment(workoutDate).tz(newTimezone, true).startOf("day");
											form.setFieldsValue({
												workoutDate: formattedDate,
											});
										}

										if (workoutDate) {
											form.validateFields(["workoutDate"]);
										}
										if (workoutTime) {
											form.validateFields(["workoutTime"]);
										}
									}}
								/>
							</div>
						</div>
					)}
					<div className="sb-workout-details-form__form__field">
						<Toggle
							initial={!!values.is_free}
							name="free"
							text="Free"
							updateToggles={setIsFree}
							tooltipTitle="This is only for use by API Partners."
						/>
					</div>
					<div className="sb-workout-details-form__form__actions">
						<Button
							onClick={onPreviousStep}
							className="sb-release-schedule-form__form__button secondary-filled"
							chevronLeft
							uppercase
						>
							<Paragraph2>Back</Paragraph2>
						</Button>
						<Button type="submit" loading={loading} disabled={loading} className="primary-filled" saveIcon uppercase>
							<Paragraph2>Next</Paragraph2>
						</Button>
					</div>
				</Form>
			</div>
		</StyledWorkoutDetailsForm>
	);
};

export default WorkoutDetailsForm;

