// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback } from "react";
import cx from "classnames";
import { Form, Modal } from "antd";
import { useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { FormInput } from "components/Common/Form/FormInput";
import { FormSelect } from "components/Common/Form/FormSelect";
import { Heading2, Paragraph12 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Actions and styles
// -----------------------------------------------------------------------------
import { createLead } from "store/slices";
import { useCreateLeadMutation } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ContactSalesModal = ({ open, setOpen, className }) => {
	const dispatch = useDispatch();
	const [createLeadTrigger, createLeadResponse] = useCreateLeadMutation();

	const onFinishForm = useCallback(
		(values) => {
			// dispatch(
			// 	createLead({
			// 		first_name: values.firstName,
			// 		last_name: values.lastName,
			// 		email_address: values.email,
			// 		phone_number: values.phone,
			// 		business_type: values.businessType,
			// 		member_count: values.memberCount,
			// 	})
			// );
			createLeadTrigger({
				first_name: values.firstName,
				last_name: values.lastName,
				email_address: values.email,
				phone_number: values.phone,
				business_type: values.businessType,
				member_count: values.memberCount,
			});

			setOpen(false);
		},
		[dispatch, setOpen, createLeadTrigger]
	);

	return (
		<Modal
			className={cx(className, "sb-contact-sales-modal")}
			title={<Heading2>Contact Sales</Heading2>}
			centered
			open={open}
			closable={false}
			onOk={() => setOpen(false)}
			onCancel={() => setOpen(false)}
			width={656}
			footer={null}
			maskClosable={false}
		>
			<div className="sb-contact-sales-modal__subtitle">
				<Paragraph12>
					Fill out the form below and one of our SweatBase experts will advise you on the best solutions to manage your
					business.
				</Paragraph12>
			</div>
			<div className="sb-contact-sales-modal__form">
				<Form onFinish={onFinishForm} layout="vertical">
					<div className="sb-contact-sales-modal__form__inline">
						<FormInput name="firstName" label="First Name" rules={[{ required: true, message: "Field Required" }]} />
						<FormInput name="lastName" label="Last Name" rules={[{ required: true, message: "Field Required" }]} />
					</div>
					<FormInput
						name="email"
						label="Email Address"
						rules={[{ type: "email" }, { required: true, message: "Field Required" }]}
					/>
					<FormInput name="phone" label="Phone Number" rules={[{ required: true, message: "Field Required" }]} />
					<div className="sb-contact-sales-modal__form__inline">
						<FormSelect
							name="businessType"
							label="Type of business"
							placeholder="Type of business"
							rules={[{ required: true, message: "Field Required" }]}
							options={[
								{ value: "trainer", label: "Personal Trainer" },
								{ value: "independent", label: "Independent Gym" },
								{ value: "corporate", label: "Corporate Gym" },
								{ value: "franchise", label: "Gym Franchise" },
							]}
						/>
						<FormSelect
							name="memberCount"
							label="Member Count"
							placeholder="Select member count"
							rules={[{ required: true, message: "Field Required" }]}
							options={[
								{ value: "1-100", label: "From 1 to 100 members" },
								{ value: "101-300", label: "From 101 to 300 members" },
								{ value: "301-1000", label: "From 301 to 1,000 members" },
								{ value: "1000+", label: "Over 1,000 members" },
							]}
						/>
					</div>
					<Form.Item>
						<Button className="primary-filled" type="submit" uppercase chevronRight>
							{"SUBMIT FORM & CONTINUE"}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</Modal>
	);
};

export default ContactSalesModal;

