// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// -------------------------------------------------------------------------------
// Component, selectors and others
// -------------------------------------------------------------------------------
import { Heading2, Paragraph12 } from "themes/default/_typography";
import { MembershipSelection } from "components/Payments/MembershipSelection";
import { selectSelectedProduct, selectCurrentMembershipDetails } from "store/selectors";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembershipSettingsPage from "./styles";
import { updateSelectedProduct, updateCurrentMembershipDetails } from "store/slices";
import {
	useStripeGetCustomerSubscriptionsMutation,
	useGetDomainGymQuery,
	useStripeGetSubscriptionsForGymMutation,
} from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { paymentLevels } from "helpers/constants";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembershipSettingsPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedProduct = useSelector(selectSelectedProduct);
	const currentMembershipDetails = useSelector(selectCurrentMembershipDetails);
	const [hasMembership, setHasMembership] = useState(false);
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const { data: domainGymData } = useGetDomainGymQuery(gym);

	const [getCustomerSubscriptionsTrigger, getCustomerSubscriptionsResponse] =
		useStripeGetCustomerSubscriptionsMutation();
	const [getSubscriptionsForGymTrigger] = useStripeGetSubscriptionsForGymMutation();
	const [currentPricing, setCurrentPricing] = useState(0);
	const [planDetails, setPlanDetails] = useState();
	const fetchCustomerSubscriptions = async () => {
		try {
			await getCustomerSubscriptionsTrigger({
				customerId: user.customer_id,
			});
		} catch (error) {
			console.error("ERROR: ", error);
		}
	};

	useEffect(() => {
		if (getCustomerSubscriptionsResponse.isSuccess) {
			const data = getCustomerSubscriptionsResponse.data.subscriptions[0];
			const planDetails = paymentLevels[data?.plan?.product?.name];
			setPlanDetails(planDetails);
		}
	}, [getCustomerSubscriptionsResponse]);

	const fetchProductForGym = async () => {
		try {
			let data = currentMembershipDetails;
			if (domainGymData?.name) {
				if (!data) {
					const gymProductResponse = await getSubscriptionsForGymTrigger({
						// TODO UPDATE TO CURRENT GYM NAME
						gymName: domainGymData.name,
					}).unwrap();

					if (gymProductResponse) {
						data = gymProductResponse;
					}
				}

				// TODO Should Fetch Current Product In Stripe
				const product = {
					name: "Pro",
					price: "$99.00",
					type: "pro",
				};

				const currentMembershipDetails = {
					id: data.id,
					product: data.id,
					unit_amount: data.unit_amount,
					type: data.type,
					membersPay: data.unit_amount / 100,
					default_price: data.default_price,
				};
				setCurrentPricing(data.unit_amount);
				dispatch(updateCurrentMembershipDetails({ currentMembershipDetails }));
				dispatch(updateSelectedProduct({ product }));
				setHasMembership(true);
			}
		} catch (error) {
			return null;
		}
	};

	useEffect(() => {
		if (!hasMembership) {
			fetchCustomerSubscriptions();
			fetchProductForGym();
		}
	}, [navigate, hasMembership, domainGymData]);

	return (
		<StyledMembershipSettingsPage className="sb-membership-settings-page">
			<div className="sb-membership-settings-page__inner">
				<div className="sb-membership-settings-page__inner__heading">
					<Heading2>Membership Settings</Heading2>
					<Paragraph12>
						Membership is what your members pay to access your content. Workouts can be set to free or membership based.
					</Paragraph12>
				</div>
				{selectedProduct && planDetails && (
					<MembershipSelection
						product={selectedProduct}
						planDetails={planDetails}
						currentPricing={currentPricing}
						setCurrentPricing={setCurrentPricing}
					/>
				)}
				{!selectedProduct && planDetails && (
					<MembershipSelection
						product={{
							name: "Pro",
							price: "$99.00",
							type: "pro",
						}}
						planDetails={planDetails}
						currentPricing={currentPricing}
						setCurrentPricing={setCurrentPricing}
					/>
				)}
				{!selectedProduct && !planDetails && (
					<div>Please complete Step 1 and subscribe to a 'SWEATBASE PLAN' first version 3</div>
				)}
				{selectedProduct && !planDetails && (
					<div>Please complete Step 1 and subscribe to a 'SWEATBASE PLAN' first version 2</div>
				)}
			</div>
		</StyledMembershipSettingsPage>
	);
};

export default MembershipSettingsPage;

